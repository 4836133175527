import Index from './components/Index.vue'
import Payment from './components/Payment.vue'
import { createRouter, createWebHistory } from 'vue-router'
const routes = [{
    name: "LoadPage",
    component: Index,
    path: '/'
}, {
    name: "Payment",
    component: Payment,
    path: '/payment'
}];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});
export default router;
// ViewOtherUser